/* eslint no-console: 0 */
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIdleTimer } from 'react-idle-timer';
import Video from '../../components/Video';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Pagination, Navigation]);

export const slideTypes = graphql`
  fragment SlideTypes on ContentfulSlideContentfulTitleSlideUnion {
    ... on ContentfulTitleSlide {
      __typename
      node_locale
      id
      title
    }
    ... on ContentfulSlide {
      __typename
      node_locale
      id
      title
      body {
        raw
      }
      media {
        credit
        altText {
          altText
        }
        media {
          file {
            contentType
            url
          }
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1920
                height: 1080
                layout: FIXED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  fragment FlipbookFragment on ContentfulFlipbook {
    slug
    inactivityTimeout
    node_locale
    slides {
      ...SlideTypes
    }
  } 
  query ($slug: String!, $locales: [String]) {
    allContentfulLocale {
      edges {
        node {
          code
          name
          default
        }
      }
    }
    allContentfulFlipbook(
      filter: {
        slug: { eq: $slug }
        node_locale: { in: $locales }
      }
    ) {
      edges {
        node {
          ...FlipbookFragment
        }
      }
    }
  }
`;

function Flipbook({ data, pageContext, location }) {
  console.log('Page data:', data);
  console.log('Page context:', pageContext);
  console.log('Page location:', location);

  const localeNodes = data.allContentfulFlipbook.edges.map((edge) => edge.node);

  // Array of multi-locale slides
  const slides = localeNodes[0].slides.map((slide, i) => localeNodes.map((node) => node.slides[i]));
  const localesInfo = data.allContentfulLocale.edges.map((edge) => edge.node);

  // Filter out current locale
  const buttonLocales = localesInfo.filter((locale) => !pageContext.locales.includes(locale.code));
  const intlNames = new Intl.DisplayNames('en', { type: 'language', languageDisplay: 'dialect' });

  // To sync slide index between locales
  const [currentSlide, setCurrentSlide] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let slideIndex = params.get('currentSlide');
    if (!slideIndex) slideIndex = 0;
    setCurrentSlide(parseInt(slideIndex, 10));
  }, []);

  // Inactivity timeout
  const { inactivityTimeout } = localeNodes[0];
  useIdleTimer({
    timeout: inactivityTimeout * 1000,
    debounce: 500,
    startOnMount: false,
    // onIdle: () => window.location.reload(false),
    onIdle: () => window.location.replace('http://localhost:3000/en-US/eating-in-space?currentSlide=0'),
  });

  const getAltText = (altObj) => {
    if (altObj) return altObj.altText;
    return 'Image';
  };

  const setUrlParam = (key, value) => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(key, value);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, null, newUrl);
    }
  };

  const onSlideChange = (swiper) => {
    const { realIndex } = swiper;
    setUrlParam('currentSlide', realIndex);
    setCurrentSlide(realIndex);
  };

  const renderLocaleButtons = () => (
    <div className="locale-buttons">
      { buttonLocales && buttonLocales.map((localeInfo) => (
        <Link
          key={localeInfo.code}
          to={`/${localeInfo.code}/${pageContext.slug}?currentSlide=${currentSlide}`}
          className={`locale-button ${localeInfo.code}`}
          draggable="false"
        >
          { intlNames.of(localeInfo.code) }
        </Link>
      ))}
    </div>
  );

  const renderTitleSlide = (slide) => (
    <SwiperSlide key={slide[0].id} className="title-slide">
      {slide.map(() => (
        <div className="center">
          <h1 className="en-header">
            Eating
          </h1>
          <h1 className="en-header2">
            in space
          </h1>
          <hr />
          <h1 className="es-header">
            Comer en el espacio
          </h1>
          <div className="wrapper">
            <div className="slide">
              <div className="pointer">
                <svg className="svgWrapper touchIcon" version="1.1" x="0" y="0" viewBox="0 0 100 125" width="300px" height="150px">
                  <path
                    width="20"
                    height="20"
                    fill="white"
                    className="st0"
                    d="M79.2,57.9c-1-3.7-3.4-6.5-7.1-6.9c-1.3,0-2.4,0.1-3.4,0.7c-0.3-1.5-1-2.8-2-3.5C65.4,47.2,64,47,63,47
          c-1.4,0-2.5,0.8-3.5,1.8c-0.4-1.1-1-2-1.8-2.6C56.5,45.3,55.1,45,54,45c-1.4,0-2.8,0.5-4,1.4V28c-0.1-4.5-3.8-7-7-7
          c-3.9,0-7,3.1-7,7v31.7l-2.1-4.2c-1.2-2.4-3.2-4.7-5.4-5.3c-1.9-0.5-3.6,0.2-5,0.9c-1.7,1-2.8,2.4-3.3,3.9c-0.5,2-0.1,4.1,0.8,6.3
          c4.6,11.2,11.6,24.5,19.2,33.3c0.2,0.2,0.5,0.3,0.8,0.3h27c0.2,0,0.4-0.1,0.6-0.2c2.6-1.9,4.9-5.5,6.8-9.7s3.4-9.2,4.1-14
          C80.2,66.1,80.3,61.6,79.2,57.9z M77.5,70.8c-0.7,4.6-2.1,9.4-3.9,13.5c-1.8,3.9-3.9,7.1-6,8.7H41.5c-7.3-8.4-14.1-21.4-18.6-32.4
          c-0.9-2.4-1.1-4-0.8-5.1c0.5-1.4,1.5-2.2,2.4-2.8c1.1-0.6,2.2-1,3.4-0.7c2.3,1.1,3.2,2.6,4.2,4.3l4,8c0.2,0.4,0.7,0.7,1.1,0.6
          c0.4-0.1,0.8-0.5,0.8-1V28c0-2.8,2.2-5,5-5s5,2.2,5,5v21c0,0.5,0.3,0.9,0.8,1c0.4,0.1,0.9-0.2,1.1-0.6C50.6,48,52.5,47,54,47
          c0.7,0,1.8,0.2,2.6,0.8S58,49.3,58,51c0,0.5,0.3,0.9,0.8,1c0.4,0.1,0.9-0.2,1.1-0.6c0.9-1.8,1.8-2.4,3.1-2.4c0.5,0,1.6,0.2,2.5,0.8
          c0.8,0.6,1.5,1.5,1.5,3.2c0,0.8,1.1,1.2,1.7,0.7c0.2-0.2,0.7-0.4,1.4-0.6C70.7,53,71.5,53,72,53c2.8,0.4,4.4,2.3,5.3,5.5
          C78.2,61.7,78.2,66.2,77.5,70.8z"
                  />
                </svg>
              </div>
            </div>
            <span style={{ margin: '20px' }}>Swipe the screen</span>
            <span>|</span>
            <span className="es-subheader">Desliza el dedo por la pantalla</span>
          </div>
        </div>
      ))}
    </SwiperSlide>
  );

  const renderSlides = slides.map((slide, i) => {
    // eslint-disable-next-line no-underscore-dangle
    if (slide[0].__typename === 'ContentfulTitleSlide') return renderTitleSlide(slide);
    return (
      <SwiperSlide key={slide[0].id}>
        {({ isActive }) => (
          <div>
            {/* Title and body for each locale */}
            {slide.map((locale) => (
              <div className={`${locale.node_locale} text-container ${i === 5 || i === 9 ? 'text-low' : ''}`} key={locale.node_locale}>
                <h2>{(locale.title && locale.title) || null}</h2>
                <div className="separator" />
                <div className="body">
                  {(locale.body && renderRichText(locale.body)) || null}
                </div>
              </div>
            ))}
            {/* Media */}
            {(slide[0].media && slide[0].media.media) && (
              <div className="media">
                {
                (slide[0].media.media.file.contentType).includes('video')
                  ? <Video src={slide[0].media.media.localFile.publicURL} active={isActive} />
                  : (
                    <GatsbyImage
                      image={getImage(slide[0].media.media.localFile)}
                      alt={getAltText(slide[0].media.altText)}
                      loading="eager"
                    />
                  )
                }
                <span className="credit">{slide[0].media.credit}</span>
              </div>
            )}
          </div>
        )}
      </SwiperSlide>
    );
  });

  return (
    <>
      {currentSlide !== null
      && (
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides
        loop
        simulateTouch={false}
        pagination={{
          clickable: false,
        }}
        direction="horizontal"
        className={localeNodes[0].slug}
        onSlideChange={onSlideChange}
        initialSlide={currentSlide}
      >
        {renderSlides}
      </Swiper>
      )}
      {renderLocaleButtons()}
    </>
  );
}

Flipbook.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Flipbook;
